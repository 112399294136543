import { FC, useMemo } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { parsePhoneNumber } from 'awesome-phonenumber'

import { Button, Input, ErrorMessage, FileInputMultiple } from 'ds/components'
import { draftjsToHtml, Editor, htmlToDraftjs } from 'ds/components/Editor'

import { notificationService } from 'lib/services/notificationService'
import { getWhatsAppTextMessageContent } from 'lib/utils/getWhatsAppTextMessageContent'
import { useForm } from 'lib/hooks/useForm'
import { mutationOnSuccess } from 'lib/utils/mutationUtils'

type Props = {
    invoiceId?: number
    paymentId?: number
    customerId?: number

    from: string
    replyTo: string | undefined
    to: string | undefined
    cc: string
    subject: string
    message: string
    attachments: string[]
    tel: string | undefined

    attachInvoice?: boolean

    onSuccess: () => void
}

export const SendNotificationForm: FC<Props> = ({
    invoiceId,
    paymentId,
    customerId,
    onSuccess,
    from,
    replyTo,
    to,
    cc,
    subject,
    message,
    attachments,
    attachInvoice,
    tel,
}) => {
    const queryClient = useQueryClient()

    const initialValues = useMemo(
        () => ({
            to: to || '',
            cc: cc || '',
            subject: subject || '',
            message: message || '',
            editorState: htmlToDraftjs(message || ''),
            attachments,
        }),
        [to, cc, subject, message, attachments]
    )

    const {
        formData,
        formContext,
        formError,
        handleBlur,
        handleChange,
        setFormError,
        getFormError,
    } = useForm(initialValues, { to: { type: 'email' } })

    const { isPending, mutate } = useMutation({
        mutationFn: notificationService.postNotification,
        onError: () => setFormError('Algo salió mal.'),
        onSuccess: mutationOnSuccess({
            toastSuccess: 'Mensaje enviado.',
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: ['invoice'] })
                onSuccess()
            },
        }),
    })

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (getFormError()) return false

        mutate({
            invoice_id: invoiceId,
            payment_id: paymentId,
            customer_id: customerId,
            from,
            replyTo,
            to: formData.to,
            cc: formData.cc,
            subject: formData.subject,
            message: draftjsToHtml(formData.editorState),
            attachments: formData.attachments,
            attachInvoice,
        })
    }

    let parsedPhone: string | undefined
    if (tel) {
        const parsed = parsePhoneNumber(tel)
        if (parsed.valid) {
            parsedPhone = `${parsed.countryCode}${parsed.number.significant}`
        }
    }

    return (
        <form name="SendNotificationForm" onSubmit={handleSubmit}>
            <div className="neutral-600 stacked-sm">
                De {from}, respuestas a {replyTo}
            </div>
            <div className="row stacked-sm">
                <Input
                    className="col"
                    formContext={formContext.to}
                    label="Para"
                    mask="multi-email"
                    value={formData.to}
                    onChange={(value) => handleChange('to', value)}
                    onBlur={() => handleBlur('to')}
                />
                <Input
                    className="col"
                    formContext={formContext.cc}
                    label="CC"
                    mask="multi-email"
                    value={formData.cc}
                    onChange={(value) => handleChange('cc', value)}
                    onBlur={() => handleBlur('cc')}
                />
            </div>
            <Input
                className="stacked-md"
                formContext={formContext.subject}
                label="Asunto"
                value={formData.subject}
                onChange={(value) => handleChange('subject', value)}
                onBlur={() => handleBlur('subject')}
            />
            <Editor
                className="stacked-md"
                value={formData.editorState}
                onChange={(value) => handleChange('editorState', value)}
            />

            <FileInputMultiple
                className="flex-fill"
                onChange={(value) => handleChange('attachments', value)}
                placeHolderValues={attachInvoice ? ['Nota de venta.pdf'] : []}
                s3directory="email-attachments"
                value={formData.attachments}
            />

            <div>
                <div className="inline inline-md">
                    <Button
                        type="submit"
                        disabled={!formData.to || isPending}
                        className="stacked-sm"
                    >
                        {isPending ? 'Enviando...' : 'Enviar correo'}
                    </Button>
                </div>
                <div className="inline inline-md">
                    <Button
                        disabled={!parsedPhone}
                        icon="whatsapp"
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            if (!parsedPhone) return
                            // for now, client side.
                            const textMessage = getWhatsAppTextMessageContent({
                                subject: formData.subject,
                                message: draftjsToHtml(formData.editorState),
                                fileUrls: attachments,
                            })
                            const url = `https://wa.me/${parsedPhone}?text=${textMessage}`
                            window.open(url, '_blank')
                        }}
                    >
                        Enviar WhatsApp
                    </Button>
                </div>
                <ErrorMessage>{formError}</ErrorMessage>
            </div>
        </form>
    )
}
